<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        CC - Хэрэглэгчийн нэвтрэх эрхийн мэдээлэл - {{ this.total }}
        <p style="color:red; font-size: 10px; margin: 0;"></p>
      </h3>
    </div>
    <el-row :gutter="0" v-loading="loading">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-row :gutter="0">
            <el-col :span="24">
              <el-row>
                <el-input
                  style="width: 200px;"
                  prefix-icon="el-icon-search"
                  v-model="outlet_name"
                  size="mini"
                  clearable
                  placeholder="Хэрэглэгчийн нэр"
                  onfocus=""
                />
              </el-row>
              <el-table
                style="width: 100%"
                :data="
                  users.filter(
                    data =>
                      !username ||
                      (!['', undefined, null].includes(data.username) &&
                        data.username
                          .toLowerCase()
                          .includes(username.toLowerCase()))
                  )
                "
              >
                <el-table-column type="index" label="№" width="80" />
                <el-table-column prop="username" label="Username" width="200" />
                <el-table-column
                  prop="user_status"
                  label="Баталгаажсан эсэх"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.user_status === 'CONFIRMED'"
                      type="success"
                      >CONFIRMED</el-tag
                    >
                    <el-tag
                      v-if="scope.row.user_status != 'CONFIRMED'"
                      type="danger"
                      >UNCONFIRMED</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="groups"
                  label="Баталгаажсан эсэх"
                  width="250"
                >
                  <template slot-scope="scope">
                    <label>
                      {{ scope.row.groups.toString() }}
                    </label>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from "../helpers/services";
export default {
  created() {
    this.getCognitoUserList();
  },
  data() {
    return {
      users: [],
      total: 0,
      outlet_name: null,
      email: "",
      loading: false
    };
  },
  methods: {
    getCognitoUserList() {
      this.loading = true;
      services.getCognitoUserList("cc").then(response => {
        if (response.data.status === "success") {
          this.users = response.data.data.allUsers;
          this.total = response.data.data.total;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
